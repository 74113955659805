/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
	border: 1px solid #f0f0f0;
	border-radius: 10px;
	margin-top: 50px;
	background: white;
	overflow: hidden;
}

.accordion__item + .accordion__item {
	border-top: 1px solid #f0f0f0;
}

.accordion__button {
	color: #444;
	cursor: pointer;
	padding: 18px 25px;
	width: 100%;
	text-align: left;
	border: none;
	text-transform: capitalize;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #f0f0f0;
}

.accordion__button:hover {
	background-color: #ddd;
}

.accordion__button:before {
	display: inline-block;
	content: "";
	height: 10px;
	width: 10px;
	margin-right: 12px;
	border-bottom: 2px solid currentColor;
	border-right: 2px solid currentColor;
	transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
	transform: rotate(45deg);
}

[hidden] {
	display: none;
}

.accordion__panel {
	padding: 20px 40px;
	animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
