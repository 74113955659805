.progress {
	position: relative;
	padding: 0 0 0 5rem;
	margin: 2rem 0 0;
	list-style: none;
}
.progress__item {
	position: relative;
	min-height: 75px;
	padding-left: 0.5rem;
	margin-bottom: 10px;
}
.progress__item:after {
	content: "";
	position: absolute;
	top: 0.5rem;
	left: -4.5rem;
	width: 28px;
	aspect-ratio: 1;
	border-radius: 50%;
	border: 2px solid var(--primary-color);
	font-weight: 400;
	font-size: 13px;
	line-height: 2rem;
	text-align: center;
	background: #fff;
	color: #392a60;
}
.progress__item:last-child:before {
	border: none !important;
}
.progress__item.progress__item--completed:before {
	content: "";
	position: absolute;
	left: -3.25rem;
	top: 33px;
	height: 70%;
	width: 1px;
	border-left: 1px solid var(--primary-color);
	border-style: dashed;
}
.progress__item.progress__item--completed:after {
	content: "\2713";
	font-weight: 400;
	background: #fff;
	color: #fff;
	background-color: var(--primary-color);
	display: flex;
	align-items: center;
	justify-content: center;
}
.progress__title {
	padding: 0.4rem 0 0.5rem;
	margin: 0;
	font-size: 1.6rem;
	font-weight: 600;
	color: #47505b;
}
.progress__info {
	counter-increment: list;
	font-size: 1.4rem;
	color: #5e6c85;
	font-weight: 400;
	padding-left: 2rem;
}
