@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--primary-color: #0132ad;
	--light-primary-color: #f3f7ff;
	--light-gray: #f2f4f7;
}

@layer components {
	.btn-primary {
		@apply py-[1.1rem] px-[2.7rem] bg-primary text-white font-medium rounded-lg text-[1.4rem];
	}

	.btn-primary:disabled::before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 25px;
		height: 25px;
		border-radius: 50%;
		border: 2px solid;
		border-top-color: white;
		-webkit-animation: loader 0.8s linear infinite;
		animation: loader 0.8s linear infinite;
	}

	.btn-primary:disabled {
		position: relative;
		color: var(--primary-color) !important;
	}

	.small-btn-primary {
		@apply py-[0.85rem] px-[2.7rem] bg-primary text-white font-medium rounded-lg text-[1.4rem];
	}

	.btn {
		@apply py-[1.1rem] px-[2rem] border border-lightGray text-[#667085] font-medium rounded-lg text-[1.4rem];
	}

	.small-btn {
		@apply py-[.5rem] px-[2rem] border border-lightGray text-[#667085] font-medium rounded-lg text-[1.4rem];
	}
}

.search-orders {
	margin-right: 5px;
	font-size: 12px;
	border: 1px solid #5e6c85 !important;
	border-radius: 5px;
	padding: 10px;
	width: 300px;
}

.search-orders-btn {
	background-color: #0132ad;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 12px;
}

.submit-signup:disabled::before {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 25px;
	height: 25px;
	border-radius: 50%;
	border: 2px solid;
	border-top-color: white;
	-webkit-animation: loader 0.8s linear infinite;
	animation: loader 0.8s linear infinite;
}

.submit-signup:disabled {
	position: relative;
	color: var(--primary-color) !important;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

.no-hide::-webkit-scrollbar {
	display: block;
}

.table *::-webkit-scrollbar {
	display: block;
}

html {
	font-size: 62.5%;
}

@media (max-width: 1200px) {
	html {
		font-size: 56.25%;
	}
}

@media (max-width: 900px) {
	html {
		font-size: 50%;
	}
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
	margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	font-size: 1.6rem;
	font-family: Plus Jakarta Sans;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
	border-color: var(--primary-color);
}

button:disabled {
	cursor: not-allowed;
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: visible;
	overflow-y: visible;
}

.table-responsive > div:first-of-type {
	overflow-x: scroll;
	overflow-y: visible;
}

.ps-menu-button:hover {
	background-color: var(--light-primary-color) !important;
	color: var(--primary-color) !important;
	border-radius: 10px;
}

.modal {
	margin-top: 50px;
	height: 800px;
}

.app-modal-content {
	border-radius: 10px;
	padding: 30px 30px;
	background: #fff;
	border: none;
	margin: 0 auto;
}

.modal-header {
	border-bottom: none;
	font-style: normal;
	font-weight: bold;
	font-size: 2rem;
	line-height: 2.4rem;
	color: var(--primary-color);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-inline: 0 !important;
	border-bottom: 2px solid var(--light-gray);
}

.modal-header::before,
.modal-header::after {
	display: none;
}

.modal-header h4.modal-title {
	font-size: 1.6rem;
	color: var(--primary-color);
	/* margin-bottom: 20px; */
}

.modal-header .close {
	padding: 10px;
	background-color: transparent;
	border: none;
	font-size: 2rem;
}

.rec-modal-body {
	padding: 0;
}

.modal-xlg {
	width: 1200px !important;
	max-width: 1200px;
}

.modal-lg {
	width: 900px !important;
	max-width: 900px;
}

.modal-normal {
	width: 750px !important;
	max-width: 900px;
}

.modal-xsm {
	width: 400px !important;
	max-width: 400px;
}

.modal-xsm .modal-title {
	font-size: 0.5rem;
}

.modal-subtitle {
	margin-bottom: 30px;
	margin-top: -20px;
	font-size: 14px;
	font-family: inherit;
	color: #5e6c85;
}

.modal-dialog {
	max-width: 90vw;
	max-height: 100%;
}

.ReactModal__Overlay--after-open {
	z-index: 1000;
	background: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content--after-open {
	border: none !important;
	background: transparent !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.popup-content {
	padding-block: 5px;
	background-color: white;
	color: #45464e;
	border: 0.5px solid #e1e2e9;
	border-radius: 4px;
}

.popup-option {
	padding: 8px 15px;
	font-weight: 400;
	font-size: 1.4rem;
	position: relative;
	overflow: hidden;
	display: flex;
	gap: 5px;
	align-items: center;
}

.popup-option:hover {
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(10px);
	cursor: pointer;
}

.control-form {
	display: block;
	width: 100%;
	height: 48px;
	padding: 6px 12px;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #f2f4f7;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.view-pwd {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 15px;
	color: #8a8383;
	cursor: pointer;
}

.has-pwd-icon {
	position: relative;
}

.forgot-submit {
	background-color: #0132ad;
	padding: 20px 50px;
	color: #fff;
	border-radius: 10px;
	margin-top: 50px;
}

.forgot-submit1 {
	background-color: transparent;
	padding: 20px 50px;
	color: #000000;
	border-radius: 10px;
	margin-top: 50px;
	border: 1px solid lightgrey;
}

.rdrDay {
	height: 2.5em !important;
}

@keyframes loader {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	html:focus-within {
		scroll-behavior: auto;
	}

	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}
