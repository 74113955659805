.modal-tabs {
	-webkit-tap-highlight-color: transparent;
	padding-inline: 0 !important;
}

.modal-tabs__tab-list {
	background-color: white;
	border-bottom: 1px solid var(--light-gray);
	margin-bottom: 2rem;
}

.modal-tabs__tab {
	display: inline-block;
	position: relative;
	list-style: none;
	padding: 8px 16px;
	cursor: pointer;
	color: #5e6c85;
	margin-right: 2px;
}

.modal-tabs__tab--selected {
	color: var(--primary-color);
	border-bottom: 2px solid var(--primary-color);
}

.modal-tabs__tab:hover {
	background: var(--light-primary-color);
	color: var(--primary-color);
}

.modal-tabs__tab:focus {
	outline: none;
}

.modal-tabs__tab-panel {
	display: none;
}

.modal-tabs__tab-panel--selected {
	display: block;
}
