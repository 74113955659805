.quantity-input {
	display: flex;
	gap: 1px;
	align-items: center;
	border-radius: 3px;
	width: fit-content;
	margin-top: 5px;
}
.quantity-input__modifier,
.quantity-input__screen {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: none;
}
.quantity-input__modifier {
	font-size: 1.5rem;
	color: var(--primary-color);
	border-radius: 50%;
	text-align: center;
	cursor: pointer;
}
.quantity-input__modifier:hover {
	background: #dadada;
}
.quantity-input__screen {
	width: 2ch;
	font-size: 1.2rem;
	text-align: center;
	font-weight: 500;
}
input.quantity-input__screen {
	width: 75px;
	border: 1px solid #f2f4f7;
	background-color: white;
	font-size: 1.2rem;
	text-align: center;
	font-weight: 500;
	padding: 4px 8px;
	outline: 1px solid #f2f4f7;
	user-select: auto;
}
