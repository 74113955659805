.react-tabs {
	-webkit-tap-highlight-color: transparent;
	padding-inline: 0 !important;
}

.react-tabs__tab-list {
	background-color: white;
	padding-inline: 2.5rem;
	padding-block: 1.2rem;
	border-bottom: 1px solid var(--light-gray);
}

.react-tabs__tab {
	display: inline-block;
	position: relative;
	list-style: none;
	padding: 8px 16px;
	cursor: pointer;
	color: #5e6c85;
	margin-right: 2px;
}

.react-tabs__tab--selected {
	background: var(--light-primary-color);
	color: var(--primary-color);
	border-radius: 6px;
}

.react-tabs__tab:hover {
	background: var(--light-primary-color);
	color: var(--primary-color);
	border-radius: 6px;
}

.react-tabs__tab:focus {
	outline: none;
}

.react-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	background: #fff;
}

.react-tabs__tab-panel {
	display: none;
	padding-inline: 2.5rem;
}

.react-tabs__tab-panel--selected {
	display: block;
	padding-inline: 2.5rem;
}

/*  */

.second-tabs__tab-list {
	margin-top: 5px;
}

.second-tabs__tab {
	display: inline-block;
	position: relative;
	list-style: none;
	padding: 10px 20px;
	cursor: pointer;
	color: #5e6c85;
	margin-right: 2px;
	font-size: 1.4rem;
	font-weight: 600;
}

.second-tabs__tab--selected {
	color: var(--primary-color);
	border-bottom: 2px solid var(--primary-color);
}

.second-tabs__tab:hover {
	color: var(--primary-color);
	border-bottom: 2px solid var(--primary-color);
}

.second-tabs__tab:focus {
	outline: none;
}

/*  */

.third-tabs {
	-webkit-tap-highlight-color: transparent;
	padding-inline: 0 !important;
}

.third-tabs__tab-list {
	background-color: white;
	padding-inline: 1.75rem;
	padding-block: 0.5rem;
	border-bottom: 1px solid var(--light-gray);
}

.third-tabs__tab {
	display: inline-block;
	position: relative;
	list-style: none;
	padding: 4px 6px;
	cursor: pointer;
	color: #5e6c85;
	margin-right: 2px;
	font-size: 1.25rem;
}

.third-tabs__tab--selected {
	background: var(--light-primary-color);
	color: var(--primary-color);
	border-radius: 6px;
}

.third-tabs__tab:hover {
	background: var(--light-primary-color);
	color: var(--primary-color);
	border-radius: 6px;
}

.third-tabs__tab:focus {
	outline: none;
}

.third-tabs__tab:focus:after {
	content: "";
	position: absolute;
	height: 5px;
	left: -4px;
	right: -4px;
	bottom: -5px;
	background: #fff;
}

.third-tabs__tab-panel {
	display: none;
}

.third-tabs__tab-panel--selected {
	display: block;
}
